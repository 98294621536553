import { FORM_VALUES, MAX_INT_VALUE, MAX_PHOTOS_LENGTH } from './constants';
import phoneOperatorValidate from './phoneOperatorValidate';

export const validateFloor = (value, values) =>
  Number(value) > 0 &&
  Number(value) <= Number(values[FORM_VALUES.totalFloors]) &&
  Number(value) <= MAX_INT_VALUE;

export const validateNumber = value =>
  Number(value) > 0 && Number(value) <= MAX_INT_VALUE;

export const validatePrice = value =>
  Number(value) >= 10 && Number(value) <= MAX_INT_VALUE;

export const validatePhotos = (value, values) => {
  return (
    value?.length >= values[FORM_VALUES.categoryValues].photosRequired &&
    value?.length <= MAX_PHOTOS_LENGTH
  );
};

export const getFloorError = (values, t) => {
  if (Number(values[FORM_VALUES.floor]) < 1 || !values[FORM_VALUES.floor]) {
    return t('new_ad.above_zero');
  }
  if (Number(values[FORM_VALUES.floor]) > MAX_INT_VALUE) {
    return t('new_ad.less_than', { value: MAX_INT_VALUE + 1 });
  }
  if (values[FORM_VALUES.totalFloors]) {
    return t('new_ad.equal_or_less', {
      value: values[FORM_VALUES.totalFloors]
    });
  }
};

export const getNumberError = field => (values, t) =>
  Number(values[field]) < 1 || !values[field]
    ? t('new_ad.above_zero')
    : t('new_ad.less_than', { value: MAX_INT_VALUE + 1 });

export const getPriceError = (values, t) =>
  Number(values[FORM_VALUES.price]) < 10 || !values[FORM_VALUES.price]
    ? t('new_ad.above_ten')
    : t('new_ad.less_than', { value: MAX_INT_VALUE + 1 });

export const getImagesError = (values, t) => {
  return values[FORM_VALUES.images]?.length <
    values[FORM_VALUES.categoryValues].photosRequired ||
    !values[FORM_VALUES.images]
    ? t('new_ad.min_photos', {
        value: values[FORM_VALUES.categoryValues].photosRequired
      })
    : t('new_ad.max_photos', { value: MAX_PHOTOS_LENGTH });
};

const getPhoneError = (values, t) =>
  values[FORM_VALUES.phone]?.length > 2 &&
  !phoneOperatorValidate(values[FORM_VALUES.phone])
    ? t('new_ad.wrong_operator')
    : t('new_ad.wrong_phone');

export const validatorsConfig = {
  [FORM_VALUES.locationId]: {
    required: values => values[FORM_VALUES.cityHasLocations],
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.cityId]: {
    required: true,
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.address]: {
    required: true,
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.coordinates]: {
    required: true,
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.paidDaily]: {
    required: values => values[FORM_VALUES.leased] === 'true',
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.buildingType]: {
    required: values => values[FORM_VALUES.categoryValues].hasBuildingType,
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.roomsAmount]: {
    required: values =>
      values[FORM_VALUES.categoryValues].hasRooms &&
      values[FORM_VALUES.categoryValues].areRoomsRequired,
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.area]: {
    required: true,
    validator: validateNumber,
    errorMessage: getNumberError(FORM_VALUES.area)
  },
  [FORM_VALUES.landArea]: {
    required: values => values[FORM_VALUES.categoryValues].hasLandArea,
    validator: validateNumber,
    errorMessage: getNumberError(FORM_VALUES.landArea)
  },
  [FORM_VALUES.floor]: {
    required: values => values[FORM_VALUES.categoryValues].hasFloor,
    validator: validateFloor,
    errorMessage: getFloorError
  },
  [FORM_VALUES.totalFloors]: {
    required: values => values[FORM_VALUES.categoryValues].hasFloor,
    validator: validateNumber,
    errorMessage: getNumberError(FORM_VALUES.totalFloors)
  },
  [FORM_VALUES.price]: {
    required: true,
    validator: validatePrice,
    errorMessage: getPriceError
  },
  [FORM_VALUES.name]: {
    required: true,
    errorMessage: 'new_ad.required'
  },
  [FORM_VALUES.email]: {
    required: true,
    validator: value =>
      /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
    errorMessage: 'new_ad.wrong_email'
  },
  [FORM_VALUES.phone]: {
    required: true,
    validator: value => value?.length >= 10 && phoneOperatorValidate(value),
    errorMessage: getPhoneError
  },
  [FORM_VALUES.categoryId]: {
    required: true,
    errorMessage: 'new_ad.should_exist'
  },
  [FORM_VALUES.images]: {
    required: true,
    validator: validatePhotos,
    errorMessage: getImagesError
  }
};

export const validateNumField = (isFloat, { value, key }) => {
  const val = value.replace(',', '.');
  const regex = isFloat ? /^[0-9]*\.?[0-9]?$/ : /^[0-9]*$/;
  const startsWithZero = isFloat ? /^0[^.]/.test(val) : /^0/.test(val);
  const validZeroInput = startsWithZero ? ['0', '0.'].includes(val) : true;
  const isValid = (regex.test(val) && validZeroInput) || key === 'Backspace';

  return { isValid, val };
};
