import { RefObject } from 'react';
import { modalContainerToHideInputCursorSelector } from '../constants';

export const getModalContainerElement = (
  containerRef: RefObject<HTMLElement | undefined>
): HTMLElement | Window =>
  containerRef?.current ||
  document.querySelector(modalContainerToHideInputCursorSelector) ||
  window;

export const getModalHeaderElement = (
  headerRef: RefObject<HTMLElement | undefined>
): HTMLElement | null => headerRef?.current || document.querySelector('header');
