import { RefObject, useEffect } from 'react';
import { useInputScrollToView } from './useInputScrollToView';
import { getModalContainerElement, getModalHeaderElement } from '../helpers';

export const useHideCaretUnderHeader = (
  headerRef: RefObject<HTMLElement | undefined>,
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement | undefined>,
  isFocused: boolean,
  containerRef: RefObject<HTMLElement | undefined>
) => {
  useInputScrollToView(headerRef, containerRef);

  const resetInputCaretStyle = () => {
    const inputElement = inputRef?.current;
    if (inputElement) {
      inputElement.style.caretColor = '';
    }
  };

  useEffect(() => {
    const containerEl = getModalContainerElement(containerRef);
    const inputElement = inputRef?.current;
    const headerElement = getModalHeaderElement(headerRef);

    const handleScroll = () => {
      if (!inputElement || !headerElement || !isFocused) return;

      const inputRect = inputElement.getBoundingClientRect();
      const headerRect = headerElement.getBoundingClientRect();

      const labelHeight = 30;
      if (inputRect.top + labelHeight < headerRect.bottom) {
        inputElement.style.caretColor = 'transparent';
      } else {
        resetInputCaretStyle();
      }

      const OFFSET_BEFORE_BLUR = 50;
      const inputBottom = inputRect.bottom;
      const headerBottom = headerRect.bottom;

      if (inputBottom <= headerBottom - OFFSET_BEFORE_BLUR) {
        inputElement.blur();
      }
    };

    if (containerEl) {
      containerEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerEl) {
        containerEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, [headerRef, inputRef, isFocused, containerRef]);

  return { resetInputCaretStyle };
};
