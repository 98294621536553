import { useEffect } from 'react';
import { getModalContainerElement, getModalHeaderElement } from '../helpers';

export const useInputScrollToView = (headerRef, containerRef) => {
  useEffect(() => {
    const handleInputEvent = () => {
      const { activeElement } = document;
      if (!activeElement || activeElement.tagName !== 'INPUT') return;

      const containerEl = getModalContainerElement(containerRef);
      const headerElement = getModalHeaderElement(headerRef);

      if (!containerEl || !headerElement) return;

      setTimeout(() => {
        const headerHeight = headerElement.offsetHeight || 0;
        const boundingRect = activeElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const isAboveView = boundingRect.top < headerHeight;
        const isBelowView = boundingRect.bottom > windowHeight;

        if (isAboveView || isBelowView) {
          const scrollOffset = boundingRect.top - headerHeight - 10;

          containerEl.scrollBy({
            top: scrollOffset,
            behavior: 'smooth'
          });
        }
      }, 0);
    };

    document.addEventListener('input', handleInputEvent);

    return () => {
      document.removeEventListener('input', handleInputEvent);
    };
  }, [headerRef, containerRef]);
};
